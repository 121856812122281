import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroWithButton from "../components/HeroWithButton"
import CallToAction from "../components/CallToAction"
import Approach from "../types/api/Approach"
import Phases from "./Phases"

interface Query {
  strapiApproach: Approach
}

interface Props {
  data: Query
}

export default function ApproachPage({
  data: {
    strapiApproach: { hero, phases },
  },
}: Props) {
  return (
    <Layout>
      <Seo title={hero.title} />
      <HeroWithButton {...hero} />
      <Phases phases={phases} />
      <CallToAction />
    </Layout>
  )
}
