import React from "react"
import styled from "styled-components"

interface Props {
  index: number
}

const Container = styled.p`
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: -0.025rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-weight: 700;
`

export default function Subtitle({ index }: Props) {
  const getPhaseWithZeroPadding = (): string => {
    const phase: string = `${index + 1}`
    if (phase.length < 2) {
      return `0${phase}`
    }
    return phase
  }

  const phaseWithPadding: string = getPhaseWithZeroPadding()

  return <Container>Phase {phaseWithPadding}</Container>
}
