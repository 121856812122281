import React from "react"
import BaseSection, { Props } from "../../../components/Section"
import getBody from "./Body"

const Section = (props: Props) => {
  const Body = getBody(props.index)

  return <BaseSection {...props} Body={Body} />
}

export default Section
