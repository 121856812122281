import React from "react"

import Section from "./Section"
import BaseSections from "../../components/Sections"
import Approach from "../../types/api/Approach"

interface Props {
  phases: Approach["phases"]
}

export default function Phases({ phases }: Props) {
  return <BaseSections Section={Section} sections={phases} />
}
