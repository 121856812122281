import React from "react"

import BaseBody, { Props } from "../../../../components/Section/Body"
import Subtitle from "./Subtitle"

const getBody = (index: number) => {
  function Body(props: Props) {
    return (
      <BaseBody {...props} beforeTitle={<Subtitle index={index} />}></BaseBody>
    )
  }

  return Body
}

export default getBody
